<template>
  <footer class="app-footer container">
    <span class="app-footer__links">
      <router-link to="/terms-and-conditions/#terms-start"
        ><u>Terms & Conditions</u></router-link
      >&nbsp;&nbsp;&nbsp;|&nbsp;&nbsp;&nbsp;
      <router-link to="/privacy-policy/#privacy-start"
        ><u>Privacy Policy</u></router-link
      >&nbsp;&nbsp;&nbsp;|&nbsp;&nbsp;&nbsp;
      <span>RoadRunner Technologies Inc. 2021</span>
    </span>
  </footer>
</template>

<script>
  export default {
    name: 'AppFooter',
  }
</script>

<style lang="scss">
  .app-footer {
    margin-top: 220px;
    margin-bottom: 48px;

    @media (max-width: 1200px) {
      margin-top: 37px;
      margin-bottom: 25px;
    }

    .app-footer__links {
      font-family: Lato;
      font-size: 14px;
      font-style: normal;
      font-weight: 500;
      line-height: 36px;
      letter-spacing: 0.02em;
      text-align: left;
      color: #353749;

      a {
        color: #353749;
        text-decoration: none;
      }

      @media (max-width: 1200px) {
        font-size: 12px;
      }
    }
  }
</style>
