<template>
  <header class="app-header" :class="{ 'app-header--sticky': isSticky }">
    <div class="app-header__inner">
      <div>
        <img src="../../assets/R_logo.png" />
      </div>

      <ul :class="{ 'mobile-nav-opened': mobileMenuOpened }" v-if="onHome">
        <li>
          <a
            @click="mobileMenuOpened = false"
            href="#roadrunner"
            v-smooth-scroll
            >RoadRunner</a
          >
        </li>
        <li>
          <a @click="mobileMenuOpened = false" href="#service" v-smooth-scroll
            >Service</a
          >
        </li>
        <li>
          <a
            @click="mobileMenuOpened = false"
            href="#business-model"
            v-smooth-scroll
            >Business Model</a
          >
        </li>
        <li>
          <router-link @click="mobileMenuOpened = false" to="/gifting-services"
            >Gifting Services</router-link
          >
        </li>
        <li>
          <a @click="mobileMenuOpened = false" href="#contact" v-smooth-scroll
            >Contact</a
          >
        </li>
      </ul>

      <ul :class="{ 'mobile-nav-opened': mobileMenuOpened }" v-if="!onHome">
        <li>
          <router-link @click="mobileMenuOpened = false" to="/home/#roadrunner"
            >RoadRunner</router-link
          >
        </li>
        <li>
          <router-link @click="mobileMenuOpened = false" to="/home/#service"
            >Service</router-link
          >
        </li>
        <li>
          <router-link
            @click="mobileMenuOpened = false"
            to="/home/#business-model"
            >Business Model</router-link
          >
        </li>
        <li>
          <router-link @click="mobileMenuOpened = false" to="/gifting-services"
            >Gifting Services</router-link
          >
        </li>
        <li>
          <router-link @click="mobileMenuOpened = false" to="/home/#contact"
            >Contact</router-link
          >
        </li>
      </ul>

      <img
        @click="mobileMenuOpened = false"
        class="mobile-icon"
        v-if="mobileMenuOpened"
        src="../../assets/close.png"
      />
      <img
        @click="mobileMenuOpened = true"
        class="mobile-icon"
        v-else
        src="../../assets/burger_menu.png"
      />
    </div>
  </header>
</template>

<script>
  export default {
    name: 'Header',

    data() {
      return {
        isSticky: false,
        mobileMenuOpened: false,
      }
    },

    computed: {
      onHome() {
        return this.$route.path === '/home/'
      },
    },

    created() {
      window.addEventListener('scroll', this.stickTheHeaderWhileScrolled)
    },

    destroyed() {
      window.removeEventListener('scroll', this.stickTheHeaderWhileScrolled)
    },

    methods: {
      stickTheHeaderWhileScrolled() {
        const scrolledABitDown = window.scrollY >= 50

        if (scrolledABitDown) {
          this.isSticky = true
        } else {
          this.isSticky = false
        }
      },
    },
  }
</script>

<style lang="scss" scoped>
  .app-header {
    top: 0;
    position: sticky;
    background: #fff;
    transition: 0.3s all;
    z-index: 111;
    height: 74px;
    padding-top: 54px;

    @media (max-width: 1200px) {
      padding-top: 32px;
      position: fixed;
      height: unset;
      left: 0;
      right: 0;
    }

    .app-header__inner {
      display: flex;
      justify-content: space-between;
      padding: 0 24px;
      max-width: 1450px;
      margin: 0 auto;
      position: relative;

      @media (max-width: 1200px) {
        justify-content: center;
      }
    }

    &.app-header--sticky {
      padding-top: 16px;
      padding-bottom: 16px;
      box-shadow: 0 4px 2px -3px #8080801a;
    }

    img {
      width: 64px;

      @media (max-width: 1200px) {
        width: 42px;
      }
    }

    ul {
      display: flex;
      margin: 0;
      padding: 0;
      list-style: none;
      align-items: center;

      @media (max-width: 1200px) {
        position: absolute;
        top: 68px;
        left: 0;
        flex-direction: column;
        width: 100%;
        align-items: flex-start;
        background: #fff;
        padding-left: 25vw;
        transition: 250ms;
        height: 0;
        overflow: hidden;

        &.mobile-nav-opened {
          height: 100vh;
          padding-top: 68px;
        }
      }
    }

    a {
      font-family: Lato;
      font-size: 16px;
      font-style: normal;
      font-weight: 500;
      line-height: 72px;
      letter-spacing: 0.04em;
      text-align: left;
      color: #000;
      text-decoration: none;
      margin-left: 60px;

      @media (max-width: 1200px) {
        margin-left: 0;
        font-family: CabinetGrotesk-Regular;
        font-size: 24px;
        font-style: normal;
        font-weight: 400;
        line-height: 72px;
        letter-spacing: 0.04em;
        text-align: left;

        &:hover {
          color: #1f7a8c;
        }
      }
    }

    .mobile-icon {
      position: absolute;
      top: 12px;
      right: 28px;
      width: 29px;
      cursor: pointer;
      display: none;

      @media (max-width: 1200px) {
        display: block;
      }
    }
  }
</style>
